.btn {
  border-radius: 6px;
  padding: 0 20px;

  &:disabled {
    cursor: not-allowed;
  }

  &.s-s {
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
  }

  &.s-n {
    height: 40px;
    line-height: 40px;
  }

  &.s-l {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
  }
}
