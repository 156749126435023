.bhg-card {
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .bhg-card-image-container {
    text-align: center;
    margin: 0 0 20px;

    img {
      max-width: 320px;
    }
  }

  .bhg-card-title {
    margin: 0 0 20px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
  }

  .bhg-card-content {
    margin: 0 0 20px;
  }

  .btn {
    margin: 0 0 20px;
  }
}
