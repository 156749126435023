@import "../../../shared/variables.scss";

.form-control {
  height: $input-height;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  margin: $input-margin;
  padding: $input-padding;
  background-color: $input-background-color;
  border-radius: $input-border-radius;
  border-color: $input-border-color;
  border-width: $input-border-width;
  transition: $input-transition;

  &:active, &:focus {
    outline: 0;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    background-color: $input-background-color;
    color: $input-color;
    border-color: $input-border-color;
  }

  &.error {
    box-shadow: 0px 0px 7px 1px rgba($error-red, 0.75);
  }
}
