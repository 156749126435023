.BHG-toggle {
  height: 20px;
  width: 45px;
  padding: 2px;
  border-radius: 20px;
  background: lightgray;
  cursor: pointer;
  transition: all .5s;

  &.active {
    background: lightgreen;

    .slider {
      margin-left: calc(100% - 16px);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  .slider {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: white;
    transition: all .5s;
    margin-left: 0;
  }
}
