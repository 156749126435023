@import "../../../shared/variables.scss";

.bhg-code-input {
  display: flex;

  .form-control, .code-input-divider {
    max-width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 4px 0 0;
  }

  .code-input-divider {
    margin: 0 10px 0 6px;
  }
}
