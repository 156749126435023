.bhg-preview-password {
  position: relative;

  .preview-toggle {
    position: absolute;
    right: 20px;
    top: calc(50% - 11px);
    cursor: pointer;
  }
}
