@import '~react-day-picker/lib/style.css';
@import "../../../shared/variables.scss";

.bhg-input-date-picker {
  .DayPickerInput-Overlay {
    &.bottom {
      bottom: unset;
    }

    .DayPicker-Caption {
      > div {
        display: inline-block;
      }

      .active-year {
        margin-left: 10px;
        cursor: pointer;
        border: 1px solid grey;
        border-radius: 6px;
        padding: 0 10px;

        .day-picker-years {
          max-height: 100px;
          overflow: auto;
          position: absolute;
          background: $white;
          width: 65px;
          box-shadow: $card-box-shadow;
          border-radius: 6px;
          z-index: 999;

          > div {
            cursor: pointer;
            padding: 2px 5px;

            &:hover {
              background: rgba(grey, 0.2);
            }
          }
        }
      }
    }
  }

  .form-control:read-only {
    background-color: $input-background-color;
    cursor: pointer;
  }
}
