@import "../../../shared/variables.scss";

.bhg-modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
}

.bhg-modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media (max-width: ($tablet-portrait  - 1px)) {
    max-width: 90% !important;
  }

  .bhg-modal-buttons {
    display: flex;
    justify-content: flex-start;

    .btn {
      margin: 0 20px;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  &.animated {
    transition: all 0.5s;
  }
}
