@import "../../../shared/variables.scss";

.bhg-input-wrapper {

  .bhg-input-group {
    position: relative;
    margin: 10px 0;

    .input-err-message {
      position: absolute;
      color: $error-red;
      line-height: $input-height;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      top: 0;
      right: 30px;
      background: $input-background-color;
      z-index: 1;

      &+.bhg-preview-password {
        .preview-toggle {
          right: 10px;
        }
      }
    }
  }
}
