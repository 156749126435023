//COLORS
$black: #000000;
$white: #ffffff;
$light-grey: #e5e5e5;
$lighter-grey: #d8d8d8;
$error-red: #ff7e7f;

//Inputs
$input-height: 40px;
$input-font-size: 16px;
$input-font-weight: 400;
$input-line-height: $input-height;
$input-color: $black;
$input-background-color: rgba(247, 247, 247, 0.94);
$input-border-radius: 6px;
$input-border-color: transparent;
$input-border-width: 0;
$input-padding: 0 10px;
$input-margin: 0 0 20px;
$input-transition: box-shadow 0.55s ease-in-out;
$input-error-bs: 0px 0px 7px 1px rgba($error-red, 0.75);

$card-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

//RESPONSIVE
$mobile-large: 576px;
$tablet-portrait: 768px;
$tablet-mid: 992px;
$tablet-landscape: 1024px;
$desktop: 1200px;
$desktop-lg: 1400px;
$desktop-xl: 1780px;
