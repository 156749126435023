.bhg-checkboxes {

  &.horizontal {
    display: flex;

    label {
      margin-right: 15px;
    }
  }

  label {
    display: block;

    span {
      margin-left: 10px;
    }
  }
}
